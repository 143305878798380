import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  toggleColumns() {
    event.preventDefault();
    const button = this.buttonTarget;

    button.innerText = button.innerText === "Show all your answers" ? "Hide your answers" : "Show all your answers";

    this.element.querySelectorAll(".hidden").forEach(cell => {
      cell.classList.toggle("lg:table-cell");
    });
  }
}
