// app/javascript/controllers/grid_toggler_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton"]

  connect() {
    this.element.addEventListener('focusin', this.onFocusIn)
  }

  disconnect() {
    this.element.removeEventListener('focusin', this.onFocusIn)
  }

  onFocusIn = () => {
    this.submitButtonTarget.classList.remove('hidden')
  }

  // toggleSubmitButton(show) {
  //   const submitButton = this.containerTarget.querySelector("input[type='submit']")
  //   if (submitButton) {
  //     if (show) {
  //       submitButton.classList.remove("hidden")
  //     } else {
  //       submitButton.classList.add("hidden")
  //     }
  //   }
  // }
}


