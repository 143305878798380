// in unauthorized_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // connect() {
  //   document.addEventListener('turbo:before-fetch-response', (e) => {
  //     console.log('turbo:before-fetch-response fired');
  //     console.log(e);
  //   });
  // }

  // disconnect() {
  //   this.element.removeEventListener('turbo:before-fetch-response connected')
  // }

  intercept(event) {
    const {url, redirected} = event.detail.fetchResponse.response;
    // I was not able to intercept the 401 response, only the one after which.
    // This is response is a 200 response. I had to use the url to match.
    // This method is sufficient for my project, but not enough to be an general solution
    if (redirected && url.match("/users/sign_in")) {
      event.preventDefault()
      // Here you may ask, "shouldn't we redirect to the url in the url variable above?"
      // The reason being, devise stores the location before signing out by default,
      // to redirect the user back after a successful authorization. The url before signing out
      // here, is the turbo frame request, which is part of the the current page, therefore may not
      // an ideal target for the user to visit. The current page, which is the container of the turbo
      // frame, is a more appropriate candidate.
      // window.location = window.location.href
      window.location = "/users/sign_up?return_to=" + window.location.href
    }
  }
}