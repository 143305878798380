// app/javascript/controllers/health_record_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "select" ]
  connect() {
    console.log("is it connecting ?");
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.next(event)
    })
  }

  next(event) {
    if (event.detail.success) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }

  toggleForm(event) {
    console.log("Change");
    event.preventDefault();

    const recordId = event.currentTarget.dataset.record;
    const formDiv = document.querySelector(`div#${recordId}-form`);
    if (formDiv) {
      formDiv.style.display = formDiv.style.display === "none" || formDiv.style.display === "" ? "block" : "none";
    }
  }

  loadTemplate(event) {
    console.log("yeah");
    const selectElement = event.target;
    const healthType = selectElement.value;
    const turboFrame = document.querySelector("turbo-frame#new_health_record_for_health_record");

    const url = `/health_records/new?health_record[record_type]=${healthType}`;
    turboFrame.src = url;  // Trigger Turbo Frame to load the new content
    // frame.src=event.target.value;
    turboFrame.reload();
  }
}

