import { Application } from "@hotwired/stimulus"

// import QuestionsController from 'controllers/questions_controller.js'

const application = Application.start()

// Configure Stimulus development experience
application.debug = true
window.Stimulus   = application

export { application }
